@media screen and (max-width: 460px) {
  .main-background {
    background-color: black;
    height: 100%;
    padding-bottom: 20px;
  }

  .main_Discount_offer_top_header {
    width: 100%;

    background: rgba(32, 80, 255, 0.5);
    border-radius: 0px;
    padding-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .main_Discount_offer_top_header_upto {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
  }

  .main_Discount_offer_top_header_1500 {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
    padding-bottom: 4px;
  }

  .Header_container {
    height: 66px;
    width: 90%;
    margin-left: 5%;
    display: flex;

    align-items: center;
  }

  .Header_container .logo_Contaniner {
    height: 38px;
    width: 34%;
  }

  .Header_container .logo_Contaniner img {
    height: 38px;
    width: 38px;
  }

  .Header_container .CompanyName {
    height: 22px;
    width: 33%;
    margin-left: 8%;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .Header_container .phone_Contaniner {
    height: 27px;
    /* width: 26px; */
    margin-right: 18px;
  }

  .Header_container .phone_Contaniner img {
    height: 28px;
    /* width: 26px; */
  }

  .Header_container .whatapp_Contaniner {
    width: 39px;
    /* height: 34.84px; */
  }

  .Header_container .whatapp_Contaniner img {
    width: 39px;
    /* height: 34.84px; */
  }

  .phone-what-container {
    display: flex;
    width: 33%;
    align-items: center;
    justify-content: flex-end;
  }

  .main-header-hr {
    width: 100%;
    height: 0px;
    border: 0.5px solid #b8b8b8;
    transform: rotate(-0deg);
  }

  .Automation_text_container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 13px;
  }

  .Automation_text {
    height: 40px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
  }

  .automation-slashDay {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
  }

  .Automation_subtext {
    width: 100%;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e1e1e1;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .pay_for_intallation {
    height: 46px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
    background: #2050ff;
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 5%;
  }

  .Secured_by_container {
    width: 90%;
    margin: 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .Secured_by_container img {
    width: 76px;
    height: 17px;
  }

  .Secured_by {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    margin-right: 4px;
  }

  .mobiKwik_container {
    width: 90%;
    margin: 0 5%;
    height: 34px;
    background: linear-gradient(269.92deg, #046b08 0.62%, #478e00 98.47%);
    border-radius: 8px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobiKwik_container img {
    height: 14px;
    width: 17px;
    margin: 0px 5px;
  }

  .main-img-Cname {
    height: 22px;
    margin-left: 9px;
    top: 247px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .main-description-heading {
    /* height: 36px; */
    left: 37px;
    top: 292px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 15px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 0.2px solid #b8b8b8;
    padding-bottom: 16px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-description-subHeading {
    /* height: 18px; */
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 18px;
    margin-bottom: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-selectPlan-text-m {
    height: 38px;
    margin-left: 5%;
    margin-bottom: 15px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
  }

  .main-selectPlan-text-d {
    display: none;
  }

  input[type="radio"] {
    margin-left: 0px !important;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    /* left: -9999px; */
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked:before,
  [type="radio"]:not(:checked):before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #2050ff;
    border-radius: 100%;
    background: #121212;
  }

  [type="radio"]:checked:after,
  [type="radio"]:not(:checked):after {
    content: "";
    width: 11px;
    height: 11px;
    background: #2050ff;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked):after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .main-selectPlan-text-m span {
    height: 38px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #ffffff;
  }

  .main-radio-with-description {
    margin-left: 5%;
    width: 90%;

    left: 17px;
    top: 457px;
    background-color: #121212;
    /* background: #B8B8B8; */
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-bottom: 20px;
  }

  .Main-1Room {
    /* margin-left: 5%; */
    width: 100%;
    height: 72px;
    /* background: #121212; */
    /* box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08); */
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-radio-firstPart {
    display: flex;
    min-height: 72px;
    align-items: center;
  }

  .main-radio-firstPart {
    margin-left: 20px;
  }

  .main-radio-text-heading {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 11px;
  }

  .main-radio-text-subheading {
    height: 18px;
    margin-left: 11px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-radio-price {
    height: 20px;
    margin-right: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .main-list-container {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 13px;
    border-top: 0.2px solid #b8b8b8;
    padding-bottom: 13px;
  }

  .main-list {
    display: flex;
  }

  .main-list img {
    width: 14px;
    height: 9px;
    margin-right: 11px;
  }

  .main-list-text {
    margin-bottom: 5px;
    height: 36px;
    margin-right: 10px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
  }
  .main-list-text:last-child {
    margin-bottom: 0px;
  }

  .main-mobile-Summary {
    display: none;
  }

  .main-payButton-container {
    position: fixed;
    width: 100%;
    height: 92px;
    display: flex;
    justify-content: space-between;
    left: 0px;
    bottom: 0px;
    background: #121212;
    border-radius: 12px;
    box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.12);
    z-index: 4;
  }

  .main-payButton-value-container {
    height: 46px;
    display: flex;
    margin-left: 16px;
    margin-top: 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-payButton-rate {
    height: 24px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  .main-payButton-security {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: rgba(184, 184, 184, 0.7);
    opacity: 0.8;
  }

  .main-payButton-btn-container {
    width: 151px;
    height: 52px;
    margin-top: 20px;
    margin-right: 15px;
    background: #2050ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .main-payButton-btn-text {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  .main-faq-heading-container {
    width: 228px;
    height: 38px;
    margin-top: 14px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    margin-left: 12%;
    color: #ffffff;
  }

  .main-faq-video {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
    width: 90%;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
  }

  .main-faq-img {
    width: 144px;
  }

  .main-faq-img img {
    width: 128px;
    height: 130px;
    margin: 8px;
  }

  .main-faq-rightSide {
    width: 54%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    padding-top: 8px;
  }

  .main-faq-rightSide .head {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 4px;
  }

  .main-faq-rightSide .subHead {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-review-video {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
    width: 90%;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
  }

  .main-review-heading-container {
    width: 228px;
    height: 38px;
    margin-top: 12px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    /* or 286% */

    margin-left: 12%;
    color: #ffffff;
  }

  .main-review-rightSide {
    width: 64%;
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;
  }

  .main-review-img {
    width: 106;
    margin: 8px;
  }

  .main-review-rightSide .head {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    /* or 138% */

    color: #ffffff;
  }

  .main-review-rightSide .head img {
    width: 11px;
    height: 11px;
    margin-left: 6px;
  }

  .main-review-rightSide .date {
    height: 19px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-review-rightSide .subHead {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-review-img img {
    width: 90px;
    height: 156px;
  }

  .main-review-star img {
    width: 13.81px;
    height: 12.99px;
  }

  .main-write-review {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
    width: 90%;
    height: 52px;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #ffffff;
  }

  .desktop {
    display: none;
  }

  .bottom-wab-main {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 85px;
    margin-bottom: 45px;
  }

  .footer-logo {
    width: 140px;
    margin-bottom: 25px;
  }

  .main-footer-text {
    font-family: "Manrope";
    font-style: normal;
    padding-bottom: 20px;
    color: #999;
    line-height: 24px;
    font-size: 14px;
  }

  .footer-title-light {
    font-family: "Manrope";
    font-style: normal;
    margin-bottom: 16px;
    color: #999;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: -0.01em;
  }

  .footer-main-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .footer-main-left-Side {
    width: 100%;
  }

  .main-footer-right-Side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .button-sign-up-2-copy-copy {
    margin-bottom: 0px;
    border-radius: 10px;
  }

  .button-sign-up-2-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-top: 3px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 13px 22px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 11px;
    background-color: #2050ff;
    background-image: url(../../../public/DesktopAssets/arrow-d.svg);
    background-position: 115% 50%;
    background-size: auto 15px;
    background-repeat: no-repeat;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    font-size: 16px;
    font-family: "Manrope";
    font-style: normal;
  }

  .w-button {
    /* display: inline-block; */
    padding: 9px 15px;
    background-color: #053bff;
    color: white;
    border: 0;
    padding: 15px 24px;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    width: fit-content;
  }

  .button-sign-up-2-copy-copy:hover {
    padding-right: 54px;
    background-color: #053bff;
    background-position: 90% 50%;
    background-size: auto 14px;
    color: #fff;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  .button-sign-up-2-copy-copy {
    margin-bottom: 0px;
    border-radius: 10px;
  }

  .main-logo-socials {
    margin-top: 32px;
    width: 50%;
    display: flex;
    justify-content: space-between;
  }

  .sm-icon {
    width: 25px;
    height: 25px;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 60px;
  }
  .footer-row {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .footer-text-link {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
  }

  .footer-text-link {
    margin-top: -10px;
    margin-bottom: 0px;
    -webkit-transition: color 300ms ease;
    transition: color 300ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-decoration: none;
    font-family: "Manrope";
    font-style: normal;
  }

  a {
    background-color: transparent;
  }

  .footer-text-link:hover {
    color: #1355ff;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  .footer-title-light-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #999;
    font-family: "Manrope";
    font-style: normal;
    padding-bottom: 40px;
  }

  @supports (-webkit-touch-callout: none) {
    .main_Discount_offer_top_header_upto {
      font-weight: 600;
    }
    .pay_for_intallation {
      height: 48px;
    }
    .CompanyName {
      font-weight: 600 !important;
      margin-left: 8%;
    }
    .mobiKwik_container {
      font-weight: 600;
      height: 37px;
    }

    .main-faq-rightSide .head {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #ffffff;
      margin-bottom: 4px;
    }

    .main-faq-rightSide .subHead {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #b8b8b8;
      opacity: 0.8;
    }

    .main-review-rightSide .head {
      height: 22px;
      left: 32px;
      top: 1579px;

      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      /* or 138% */

      color: #ffffff;
    }

    .main-radio-text-heading {
      font-weight: 600;
    }

    .Pay300 {
      font-weight: 600;
    }

    .main-payButton-rate {
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 760px) {
  .main_Discount_offer_top_header {
    width: 100%;
    height: 45px;
    background: rgba(32, 80, 255, 0.5);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main_Discount_offer_top_header_upto {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
  }

  .main_Discount_offer_top_header_1500 {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
    margin-left: 9px;
  }

  .Header_container {
    height: 66px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    align-items: center;
  }

  .Header_container .logo_Contaniner {
    height: 38px;
    width: 34%;
  }

  .Header_container .logo_Contaniner img {
    height: 38px;
    width: 38px;
  }

  .Header_container .CompanyName {
    height: 22px;
    width: 33%;
    text-align: center;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  .Header_container .phone_Contaniner {
    height: 27px;
    /* width: 26px; */
    margin-right: 18px;
  }

  .Header_container .phone_Contaniner img {
    height: 28px;
    /* width: 26px; */
  }

  .Header_container .whatapp_Contaniner {
    width: 39px;
    /* height: 34.84px; */
  }

  .Header_container .whatapp_Contaniner img {
    width: 39px;
    /* height: 34.84px; */
  }

  .phone-what-container {
    display: flex;
    width: 33%;
    align-items: center;
    justify-content: flex-end;
  }

  .wab-container-top {
    display: flex;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .wab-container-top-slider {
    width: 590px !important;
  }

  .wab-container-top-other {
    width: 590px;
  }

  .Automation_text_container {
    margin-top: 15px;
  }

  .Automation_text {
    height: 63px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 43.8298px;
    line-height: 63px;
    color: #ffffff;
  }

  .automation-slashDay {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 31.307px;
    line-height: 63px;
    color: #ffffff;
  }

  .Automation_subtext {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 19.9149px;
    line-height: 31px;
    color: #e1e1e1;
    margin-bottom: 31px;
    margin-top: 7px;
  }

  .pay_for_intallation {
    height: 55.84px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16));
    background: #2050ff;
    border-radius: 12.22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 21.91px;
    line-height: 31px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Secured_by_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 42.86px;
    margin-top: 10px;
  }

  .Secured_by_container img {
    width: 118.97px;
    height: 26.61px;
  }

  .Secured_by {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 15.6535px;
    color: #ffffff;
    margin-right: 4px;
  }

  .mobiKwik_container {
    height: 53.22px;
    background: linear-gradient(269.92deg, #046b08 0.62%, #478e00 98.47%);
    border-radius: 12.52px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 15.6535px;
    line-height: 31px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobiKwik_container img {
    width: 26.61px;
    height: 21.91px;
    margin: 0px 7px;
  }

  .main-wab-navbar {
    display: flex;
  }

  .main-wab-navbar div {
    display: inline-block;
    width: max-content;

    transition: all 350ms ease;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  .main-wab-navbar div a {
    text-decoration: none;
    color: #ffffff;
    padding: 0 30px;
  }

  .main-wab-navbar div a:hover {
    color: #2050ff;
  }

  .main-background {
    background-color: black;
    padding-bottom: 20px;
    padding-top: 0px;
  }

  .both-screen {
    display: flex;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .main-mainScreen {
    width: 32%;
  }

  .main-img-Cname {
    height: 22px;
    margin-left: 16px;
    top: 247px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }

  #root {
    background-color: #121212 !important;
  }

  .main-description-heading {
    border-bottom: 0.2px solid #b8b8b8;
    padding-bottom: 15px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 15px;
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    color: #b8b8b8;
  }

  .main-description-subHeading {
    height: 15px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 15px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-selectPlan-text-d {
    height: 38px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 10px;
  }
  .main-selectPlan-text-d div {
    margin-left: 4%;
  }
  .main-selectPlan-text-m {
    display: none;
  }

  input[type="radio"] {
    margin-left: 0px !important;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    /* left: -9999px; */
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked:before,
  [type="radio"]:not(:checked):before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #2050ff;
    border-radius: 100%;
    background: #121212;
  }

  [type="radio"]:checked:after,
  [type="radio"]:not(:checked):after {
    content: "";
    width: 10px;
    height: 10px;
    background: #2050ff;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked):after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .main-selectPlan-text-d span {
    height: 38px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
  }

  .main-radio-with-description {
    width: 100%;
    cursor: pointer;
    left: 17px;
    top: 457px;
    background-color: #121212;
    /* background: #B8B8B8; */
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-bottom: 20px;
  }

  .Main-1Room {
    /* margin-left: 5%; */
    width: 100%;
    height: 72px;
    /* background: #121212; */
    /* box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08); */
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-radio-firstPart {
    display: flex;
    min-height: 72px;
    align-items: center;
  }

  .inputBox {
    width: 90%;
    background: black;
    outline: none;
    border: none;
    color: white;
  }

  .main-radio-firstPart {
    margin-left: 20px;
  }

  .main-radio-text-heading {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 11px;
  }

  .main-radio-text-subheading {
    height: 18px;
    margin-left: 11px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-radio-price {
    height: 20px;
    margin-right: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .main-list-container {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 13px;
    border-top: 0.2px solid #b8b8b8;
    padding-bottom: 15px;
  }

  .main-list {
    display: flex;
  }

  .main-list img {
    width: 14px;
    height: 9px;
    margin-right: 11px;
  }

  .main-list-text {
    margin-right: 10px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
  }

  .main-payButton-container {
    display: none;
  }

  .main-payButton-value-container {
    height: 46px;
    display: flex;
    margin-left: 16px;
    margin-top: 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-payButton-rate {
    height: 24px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  .main-payButton-security {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: rgba(184, 184, 184, 0.7);
    opacity: 0.8;
  }

  .main-payButton-btn-container {
    width: 151px;
    height: 52px;
    margin-top: 20px;
    margin-right: 15px;
    background: #2050ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .main-payButton-btn-text {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  .bottom-wab-main {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 85px;
  }

  .footer-logo {
    width: 140px;
    margin-bottom: 25px;
  }

  .main-footer-text {
    font-family: "Manrope";
    font-style: normal;
    padding-bottom: 20px;
    color: #999;
    line-height: 24px;
  }

  .footer-title-light {
    font-family: "Manrope";
    font-style: normal;
    margin-bottom: 16px;
    color: #999;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: -0.01em;
  }

  .footer-main-container {
    display: flex;
    justify-content: space-between;
  }

  .footer-main-left-Side {
    width: 33%;
  }

  .main-footer-right-Side {
    width: 50%;
    display: flex;
    justify-content: space-between;
  }

  .button-sign-up-2-copy-copy {
    margin-bottom: 0px;
    border-radius: 10px;
  }

  .button-sign-up-2-copy-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-top: 3px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px 24px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 11px;
    background-color: #2050ff;
    background-image: url(../../../public/DesktopAssets/arrow-d.svg);
    background-position: 115% 50%;
    background-size: auto 15px;
    background-repeat: no-repeat;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    font-size: 18px;
    font-family: "Manrope";
    font-style: normal;
  }

  .w-button {
    /* display: inline-block; */
    padding: 9px 15px;
    background-color: #053bff;
    color: white;
    border: 0;
    padding: 15px 24px;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    width: fit-content;
  }

  .button-sign-up-2-copy-copy:hover {
    padding-right: 54px;
    background-color: #053bff;
    background-position: 90% 50%;
    background-size: auto 14px;
    color: #fff;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  .button-sign-up-2-copy-copy {
    margin-bottom: 0px;
    border-radius: 10px;
  }

  .main-logo-socials {
    margin-top: 32px;
    width: 43%;
    display: flex;
    justify-content: space-between;
  }

  .sm-icon {
    width: 25px;
    height: 25px;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
  }

  .footer-text-link {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
  }

  .footer-text-link {
    margin-top: -10px;
    margin-bottom: 0px;
    -webkit-transition: color 300ms ease;
    transition: color 300ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-decoration: none;
    font-family: "Manrope";
    font-style: normal;
  }

  a {
    background-color: transparent;
  }

  .footer-text-link:hover {
    color: #1355ff;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  .footer-title-light-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #999;
    font-family: "Manrope";
    font-style: normal;
    padding-bottom: 40px;
  }

  .main-mobile-Summary {
    width: 66%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-plan-notSeleted {
    width: 307.54px;
    height: 169px;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-plan-notSeleted-test {
    height: 40px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
  }

  .main-plan-notSeleted-text-subHead {
    height: 40px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
    text-align: center;
  }

  .main-plan-notSeleted-text-subHeading {
    height: 40px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    text-align: center;
  }

  /* 
    .bottom-mobile-main {
        display: none;
    } */
  .main-faq-review {
    display: flex;
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
  }

  .main-faq-heading-container {
    height: 38px;
    width: 1200px;
    margin-top: 30px;
    margin-bottom: 12px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }
  .main-faq-heading-container div {
    margin-left: 4%;
  }

  .main-faq-video {
    margin-top: 18px;
    width: 384px;
    height: 146px;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-right: 2%;
    display: flex;
  }

  .main-faq-video:last-child {
    margin-top: 18px;
    width: 384px;
    height: 146px;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-right: 0%;
    display: flex;
  }

  .main-faq-video:nth-child(3n) {
    margin-top: 18px;
    width: 384px;
    height: 146px;
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-right: 0%;
    display: flex;
  }

  .main-faq-img {
    width: 144px;
    /* align-items: center;
        justify-content: center; */
    display: flex;
    padding: 10px;
    cursor: pointer;
  }

  .main-faq-img img {
    width: 128px;
    height: 128px;
  }

  .main-faq-rightSide {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .main-faq-rightSide .head {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .main-faq-rightSide .subHead {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-review-video {
    width: 384px;
    margin-top: 18px;
    margin-right: 25px;
    background: #121212;
    box-shadow: 0px 1px 18px rgb(0 0 0 / 8%);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
  }

  .main-review-video:last-child {
    width: 384px;
    margin-top: 18px;
    margin-right: 0px;
    background: #121212;
    box-shadow: 0px 1px 18px rgb(0 0 0 / 8%);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
  }

  .main-review-heading-container {
    width: 1200px;
    height: 38px;
    margin-top: 30px;
    margin-bottom: 12px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
  }
  .main-review-heading-container div {
    margin-left: 4%;
  }

  .main-review-rightSide {
    width: 250px;
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    justify-content: center;
  }

  .main-review-img {
    width: 106px;
    cursor: pointer;
  }

  .main-review-rightSide .head {
    height: 21px;
    left: 32px;
    top: 1579px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    color: #ffffff;
  }

  .main-review-rightSide .head img {
    width: 13px;
    height: 13px;
    margin-left: 4px;
  }

  .main-review-rightSide .date {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .main-review-rightSide .subHead {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* or 146% */

    color: #b8b8b8;

    opacity: 0.8;
  }

  .main-review-img img {
    width: 90px;
    height: 156px;
    margin: 8px;
  }

  .main-review-star img {
    width: 13.81px;
    height: 12.99px;
  }

  .main-write-review {
    display: none;
    /* margin-left: 10%;
        margin-right: 10%;
        margin-top: 18px;
        width: 80%;
        height: 52px;
        background: #121212;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 92px;
        color: #FFFFFF; */
  }

  .disp {
    display: none;
  }

  .phone {
    display: none;
  }
}
