@media screen and (max-width: 460px) {
    .templateUi > div {
        display: flex;
        flex-direction: row;
        gap: 2;
        
    }
    .templateUi{
        display: flex;
    justify-content: space-around;
    align-items: center;
    width: 45%;
    }
   .inputCaptcha{
    width: 50%;
   }
    .templateUiContainer{
        width: 90%;
        margin: auto;
        display: flex;
       
        /* justify-content: space-between; */
        margin-top: 14px; 
        background-color: transparent;
        gap: 14px;
    }

    .captchBorder{
        border: 0.5px solid rgba(255, 255, 255, 0.8);
        border-radius: 6px;
        height: 43px;
    }
    .captchLine{
        font-style: italic;
        text-decoration: line-through;
    }
    .color{
        color: white;
        font-size: 23px;
    }
    .inputButton{
        display: flex;
        flex-direction: column;
    }
  
    .captcha-button{
       margin-left: 3px;
       width: 25%;
       height: 43px;
       border-radius: 7px;
       text-align: center;
       color: white;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .newinputBox{
        width: 100% !important;
    }
}

@media screen and (min-width: 760px){
    .templateUi > div {
        display: flex;
        gap: 2;
    }
    .templateUiContainerMobile{
        display: none;
        visibility:hidden;
    }
    .templateUi > div > canvas{
        margin-right: 4px;
    }
    .templateUiContainer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 2%; 
        background-color: transparent;
    }
    .captchBorder{
        border: 0.5px solid rgba(255, 255, 255, 0.8);
        border-radius: 6px;
        height: 43px;
    }
    .captchInput{
        width: 100%;
    }
    .templateUi{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
        width: 18%;
        
    }
    .inputCaptcha{
        width: 80%;
    }
    .captchLine{
        font-style: italic;
        text-decoration: line-through;
    }
    .color{
        color: white;
        font-size: 23px;
    }
    .submit{
        height: 43px !important;
        position:  relative !important;
    }
    .inputButton{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
   
    .captcha-button{
        margin-left: 3px;
        width: 25%;
        height: 43px;
        border-radius: 7px;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
     }
     .capthErrBoth{
        display: flex;
        flex-direction: column;
        width: 18%;
     }
}