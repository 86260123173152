@media screen and (max-width: 460px) {
  .mSummary-top-container {
    width: 100vw;
    /* height: 100vh; */
    padding-bottom: 110px;
    background: #000000;
  }

  .selectedValue {
    color: #b8b8b8;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 157.143% */
  }

  .custom-select {
    width: 90%;
    margin-left: 5%;
    margin-top: 1rem;
    height: 43px;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 14px;
  }

  .popBox {
    width: 90%;
    margin-left: 5%;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
    border-top: none;
    border-radius: 6px;
    padding-left: 14px;
  }

  .mSummary-headder {
    display: flex;
    padding-top: 18px;
  }

  .mSummary-arrow-container {
    height: 28px;
    width: 28px;
    margin-left: 16px;
  }

  .mSummary-arrow-img {
    height: 28px;
    width: 28px;
  }

  .mSummary-arrow-text {
    height: 28px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-left: 44px;
  }

  .mSummary-top-summary {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 28px;
  }

  .mSummary-top-summary-inner-container {
    width: 100%;
    height: 72px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1b1b1b;
    border-radius: 12px;
  }

  .mSummary-Details {
    padding-left: 14px;
  }
   
  .mSummary-vocher-button {
    position: absolute;
    right: -2px;
    width: 126px;
    height: 43px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  .voucher-successfully-applied {
    color: #FFF;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 5%;
}

  .mSummary-text-heading {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .mSummary-text-subheading {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .mSummary-price {
    height: 20px;
    margin-right: 21px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-contact-form {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
    /* min-height: 176px; */
    background: #121212;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
  }

  .mSummary-contact-form-wab {
    display: none;
  }

  .mSummary-text-name {
    height: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-left: 14px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .input-group-prepend {
    width: 10%;
    display: flex;
    justify-content: center;
  }

  .mSummary-user-img {
    width: 20px;
    height: 20px;
    left: 47px;
    top: 242px;
  }

  .mSumarry-input {
    
    width: "";
    background: #121212;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  .custom {
    display: flex;
    height: 43px;
    width: 90%;
    margin-left: 5%;
    margin-top: 14px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    filter: drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08));
    border-radius: 6px;
  }
  .custom-vocher{
    
      display: flex;
      height: 43px;
      width: 88%;
      margin-left: 6%;
      margin-top: 14px;
      align-items: center;
      /* justify-content: center;  */
      /* margin-bottom: 10px; */
      filter: drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08));
      border-radius: 6px;
  
  }

  .field-error {
    color: #fe0000ad;
    width: 90%;
    margin-left: 5%;
    margin-top: 5px;
    font-size: 12px;
    font-family: "Manrope";
    font-style: normal;
    /* padding-bottom: 5px; */
  }

  .form-control {
    font-size: 20px;
    width: 80%;
    height: 22px;
    left: 82px;
    top: 242px;
    background: #121212;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    box-shadow: none !important;
    border: none;
    color: #b8b8b8;
  }

  .mSummary-plan-Summary {
    background: #121212;
    border-radius: 12px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
  }

  .mSummary-plan-Summary-upperDetail {
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 18px;
  }

  .mSummary-single-detail-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .mSummary-single-detail-name {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .mSummary-single-detail-result {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-single-detail-resultD {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(33, 154, 81, 0.9);
  }

  .mSummary-plan-Summary-lowerDetail {
    height: 56px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
  }

  .mSummary-plan-Total-container {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    justify-content: space-between;
  }

  .mSummary-plan-Total-text {
    height: 22px;
    text-align: left;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .mSummary-plan-Total-result {
    height: 22px;
    text-align: right;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-plan-partial-summary {
    width: 90%;
    margin-left: 5%;
    position: relative;
    margin-right: 5%;
    margin-top: 18px;
    height: 72px;
    background: linear-gradient(269.89deg,
        rgba(0, 250, 100, 0.2) -1.42%,
        rgba(0, 255, 77, 0.08675) 99.14%,
        rgba(0, 255, 101, 0.006) 99.15%),
      #000000;
    border: 1px solid #000000;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
  }

  .mSummary-partial-post-text {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.9);
  }

  .mSummary-plan-patial {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .mSummary-partial-post-amount {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #28e33b;
    opacity: 0.8;
  }

  .mSummary-partial-post-amount-result {
    /* width: 45px; */
    height: 22px;
    margin-right: 16px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-partial-post-text-container {
    margin-left: 20px;
  }

  .mSummary-partial-offer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    left: 16px;
    top: -7px;
    background: #f2c851;
    border-radius: 2px;
  }

  .mSummary-partial-offer-text {
    height: 10px;
    margin: 2px 3px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 10px;
    color: #000000;
  }

  .mSummary-pay-button-container {
    width: 100%;
    margin-top: 18px;
    height: 92px;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #121212;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.12);
  }

  .mSummary-pay-button-text {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  .mSummary-pay-button {
    width: 90%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  .inputBox {
    width: 80%;
    background: transparent;
    outline: none;
    border: none;
    color: white;
  }
  .mSummary-vocher-button {
    position: absolute;
    right: -2px;
    width: 100px;
    height: 43px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
   .voucher-error{
    width: 90%;
    margin-left: 5%;
    font-size: 11px !important;
   }
  @supports (-webkit-touch-callout: none) {
    .mSummary-partial-post-amount {
      font-weight: 500;
    }
  }
}

@media screen and (min-width: 760px) {
  .mSummary-top-container {
    width: 100%;
    height: 100%;
    background: #000000;
  }

  .mSummary-headder {
    display: none;
  }

  .selectedValue {
    color: #b8b8b8;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    /* 157.143% */
  }

  .custom-select {
    width: 100%;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    height: 43px;
    padding-left: 14px;
  }

  .popBox {
    width: 100%;
    border: 0.5px solid rgba(255, 255, 255, 0.8);
    border-top: none;
    border-radius: 6px;
    padding-left: 14px;
    margin-bottom: 18px;
  }

  .mSummary-arrow-container {
    height: 28px;
    width: 28px;
    margin-left: 16px;
  }

  .mSummary-arrow-img {
    height: 28px;
    width: 28px;
  }

  .mSummary-arrow-text {
    height: 28px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-left: 44px;
  }

  .mSummary-top-summary {
    display: none;
  }

  .mSummary-top-summary-inner-container {
    width: 100%;
    height: 72px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1b1b1b;
    border-radius: 12px;
  }

  .mSummary-Details {
    padding-left: 14px;
  }

  .mSummary-text-heading {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .mSummary-text-subheading {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #b8b8b8;
    opacity: 0.8;
  }

  .mSummary-price {
    height: 20px;
    margin-right: 21px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-contact-form-wab {
    width: 100%;
    margin-top: 18px;
    /* min-height: 176px; */

    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  .mSummary-contact-form {
    display: none;
  }

  .mSummary-text-name {
    height: 20px;
    padding-top: 16px;
    margin-left: 14px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: none;
  }

  .input-group-prepend {
    width: 10%;
    display: flex;
    justify-content: center;
  }

  .mSummary-user-img {
    width: 22px;
    height: 20px;
    left: 47px;
    top: 242px;
  }

  .mSumarry-input {
    /* margin-left: 15px; */
    width: "";
    background: #121212;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  form {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .custom {
    display: flex;
    height: 43px;
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    filter: drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08));
    border-radius: 6px;
  }
  .custom-vocher{
    
    display: flex;
    height: 43px;
    width: 100%;
    
    margin-top: 14px;
    align-items: center;
    /* margin-bottom: 10px; */
    filter: drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.08));
    border-radius: 6px;

}

  .field-error {
    color: #fe0000ad;
    width: 90%;
    margin-left: 5%;
    margin-top: 5px;
    font-size: 12px;
    font-family: "Manrope";
    font-style: normal;
    /* padding-bottom: 5px; */
  }

  .wab-input-msummary {
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  .wab-input-msummary-vocher {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .mSummary-vocher-button {
    position: absolute;
    right: -2px;
    width: 126px;
    height: 43px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  .vocher-input{
    width: 5%;
  }
  .voucher-successfully-applied {
    color: #FFF;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .form-control {
    font-size: 20px;
    width: 80%;
    height: 22px;
    left: 82px;
    top: 242px;
    background: transparent;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    /* box-shadow: none !important; */
    border: none;
    color: #b8b8b8;
  }

  input::placeholder {
    font-weight: 500;
    color: #b8b8b8;
  }

  .mSummary-plan-Summary {
    background: #121212;
    border-radius: 12px;
    width: 100%;
  }

  .mSummary-plan-Summary-upperDetail {
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 18px;
  }

  .mSummary-single-detail-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .mSummary-single-detail-name {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .mSummary-single-detail-result {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-single-detail-resultD {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: rgba(33, 154, 81, 0.9);
  }

  .mSummary-plan-Summary-lowerDetail {
    height: 56px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
  }

  .mSummary-plan-Total-container {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    justify-content: space-between;
  }

  .mSummary-plan-Total-text {
    height: 22px;
    text-align: left;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .mSummary-plan-Total-result {
    height: 22px;
    text-align: right;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-plan-partial-summary {
    width: 100%;
    position: relative;
    margin-top: 18px;
    height: 72px;
    background: linear-gradient(269.89deg,
        rgba(0, 250, 100, 0.2) -1.42%,
        rgba(0, 255, 77, 0.08675) 100%,
        rgba(0, 255, 101, 0.006) 99.15%),
      #000000;
    border: 1px solid #000000;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
  }

  .mSummary-partial-post-text {
    height: 22px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.9);
  }

  .mSummary-plan-patial {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .mSummary-partial-post-amount {
    height: 18px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #28e33b;
    opacity: 0.8;
  }

  .mSummary-partial-post-amount-result {
    height: 22px;
    margin-right: 16px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #ffffff;
  }

  .mSummary-partial-post-text-container {
    margin-left: 20px;
  }

  .mSummary-partial-offer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    left: 16px;
    top: -7px;
    background: #f2c851;
    border-radius: 2px;
  }

  .mSummary-partial-offer-text {
    height: 10px;
    margin: 2px 3px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 10px;
    color: #000000;
  }

  .mSummary-pay-button-container {
    width: 100%;
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .mSummary-pay-button-text {
    height: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  .mSummary-pay-button {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
}